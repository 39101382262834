import React from "react"
import { graphql, StaticQuery } from "gatsby";

import { replaceNewlineCharacters } from "../../utils/replaceNewlineCharacters";
import { replaceMarkdown } from "../../utils/replaceMarkdown";

import "./participants.scss"

const Participants = () => (
  <StaticQuery
    query={graphql`
        {
          cmsData {
            pageDescriptions(where:{page:Participants }) {
              description
            }
            participants(orderBy: name_ASC) {
              name
              description {
                markdown
              }
              logo {
                url
              }
            }
          }
        }
  `}
    render={data => (
      <div>
        <h1 className="header">Participants</h1>

        <p className="description" dangerouslySetInnerHTML={{
          __html: replaceMarkdown(data.cmsData.pageDescriptions[0].description)
        }} />

        <div className="participants">
          {data.cmsData.participants.map(participant => (
            <div className="participant-wrapper">
              <div className="participant">
                <img className="participant-logo" alt={participant.name} src={participant.logo.url} />
                <div className="participant-name">{participant.name}</div>
                <div className="participant-description" dangerouslySetInnerHTML={{
                  __html: replaceMarkdown(participant.description.markdown)
                }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
  />

)

export default Participants
