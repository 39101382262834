import React from "react"

import Layout from "../components/layout"
import Participants from "./participants/participants"

const ParticipantsPage = () => (
    <Layout>
        <Participants />
    </Layout>
)

export default ParticipantsPage
